import React, { useEffect, useRef, useState } from "react";
import { Button, Form, InputGroup, Modal, Overlay, Pagination, Table } from "react-bootstrap";
import { useFilters, usePagination, useSortBy, useTable } from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight, faAnglesLeft, faAnglesRight, faSort, faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import { Columns, Save } from "react-feather";

const ColumnList = (props) => {
    const { allColumns, showAllChecked, setShowAllChecked } = props;

    return (
        <React.Fragment>
            <div
                // {...props}
                style={{ backgroundColor: "var(--bs-body-bg)", border: "1px solid black", borderRadius: 3, maxHeight: "512px", ...props.style }}
                className="p-2 overflow-auto">
                <Form.Check
                    type={"checkbox"}
                    id={"show-all"}
                    label={<><i>Show all</i></>}
                    checked={showAllChecked}
                    name={"show-all"}
                    onChange={(e) => {
                        setShowAllChecked(e.target.checked);
                        allColumns.forEach((col) => {
                            col.toggleHidden(!e.target.checked);
                        });
                    }}
                    style={{ color: "var(--bs-body-color)" }}
                />
                {allColumns.map((col) =>
                    <React.Fragment>
                        <Form.Check
                            type={"checkbox"}
                            id={`default2-${col.id}`}
                            label={col.name}
                            checked={col.isVisible}
                            name={col.name}
                            onChange={(e) => {
                                col.toggleHidden(!e.target.checked);
                                if (!e.target.checked) {
                                    setShowAllChecked(false);
                                }
                            }}
                            style={{ color: "var(--bs-body-color)" }}
                        />
                    </React.Fragment>
                )}
            </div>
        </React.Fragment>
    );
};

const ExportButton = (props) => {
    const { title, onClick } = props;
    const [show, setShow] = useState(false);

    return (
        <>
            <Button variant="light" onClick={(e) => { setShow(true); }} className="ms-1">
                <div className="d-flex justify-content-between align-items-center"><Save /><div className="d-none d-xl-inline">{title}</div></div>
            </Button>
            {/* <Button className="me-1 mb-1 mb-sm-0" onClick={() => { setShow(true) }} variant={"primary"} disabled={countNumber <= 0} >{title}</Button> */}
            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton><Modal.Title>{title}</Modal.Title></Modal.Header>
                <Modal.Body>
                    This will create a CSV export file for download.
                    This will be done in the background, and may take some time, depending on the size of the export.
                    The export will appear in your browser downloads when complete.
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={(e) => { setShow(false); onClick(e); }} variant={"primary"}>{title}</Button>
                    <Button onClick={() => setShow(false)} variant={"secondary"}>Cancel</Button>
                </Modal.Footer>
            </Modal>

        </>);
};

const ColumnSelector = (props) => {
    const { allColumns } = props;
    const target = useRef(null);
    const [showColumnsPicker, setShowColumnsPicker] = useState(false);
    const [showAllChecked, setShowAllChecked] = useState(true);

    useEffect(() => {
        // Each time we show the columns, workout if we should have the show all checked or not
        // Only work it out if we are showing the columns
        if (!showColumnsPicker) return;

        var newValue = true;

        allColumns.forEach((col) => {
            if (!col.isVisible) {
                newValue = false;
            }
        });

        setShowAllChecked(newValue);
    }, [showColumnsPicker]);

    return (
        <React.Fragment>

            <Button variant="light" ref={target} onClick={() => setShowColumnsPicker(!showColumnsPicker)} className="ms-1">

                <div className="d-flex justify-content-between align-items-center"><Columns /><div className="d-none d-xl-inline">Columns</div></div>
            </Button>
            <Overlay target={target.current} show={showColumnsPicker} placement="auto" rootClose={true} rootCloseEvent="click" onHide={(e) => { setShowColumnsPicker(false); }} >
                {({
                    placement: _placement,
                    arrowProps: _arrowProps,
                    show: _show,
                    popper: _popper,
                    hasDoneInitialMeasure: _hasDoneInitialMeasure,
                    ...props
                }) => (
                    <div
                        {...props}
                        style={{
                            position: 'absolute',
                            // backgroundColor: 'rgba(255, 100, 100, 0.85)',
                            padding: '2px 10px',
                            color: 'white',
                            borderRadius: 3,
                            ...props.style,
                        }}
                    >
                        <ColumnList allColumns={allColumns} showAllChecked={showAllChecked} setShowAllChecked={setShowAllChecked} />
                    </div>
                )}
            </Overlay>
        </React.Fragment>
    );
};

const TopBottom = (props) => {
    const { tableInstance, localPageSize, setLocalPageSize, controlledCount, exportData } = props;

    const {
        allColumns,
        canPreviousPage,
        canNextPage,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        state: { pageIndex, pageSize, sortBy, filters },
    } = tableInstance;

    return (
        <React.Fragment>
            <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex justify-content-center">
                    <InputGroup size={"sm"}>
                        <InputGroup.Text >Show</InputGroup.Text>
                        <Form.Select size={"sm"} value={localPageSize} onChange={(e) => { setLocalPageSize(e.target.value); }} >
                            <option value={10}>10</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                            <option value={75}>75</option>
                            <option value={100}>100</option>
                        </Form.Select>
                        <InputGroup.Text >entries per page</InputGroup.Text>
                    </InputGroup>
                    <ColumnSelector allColumns={allColumns}></ColumnSelector>
                    <ExportButton title={"Export"} onClick={(e) => { exportData(pageIndex, pageSize, sortBy, filters); }} ></ExportButton>
                </div>
                {controlledCount > 0 && <div>{`Showing entries ${((pageIndex) * pageSize) + 1} to ${((pageIndex + 1) * pageSize)} of ${controlledCount}`}</div>}
                <div>
                    <Pagination className="m-0 p-0">
                        <Pagination.Item onClick={(e) => { gotoPage(0) }} disabled={!canPreviousPage} ><FontAwesomeIcon fixedWidth icon={faAnglesLeft} /></Pagination.Item>
                        <Pagination.Item onClick={(e) => { previousPage() }} disabled={!canPreviousPage}><FontAwesomeIcon fixedWidth icon={faAngleLeft} /></Pagination.Item>
                        {/* <Pagination.Item><input type="number" readOnly value={pageIndex + 1} style={{ border: 0, width: "60px" }} className="m-0 p-0" onChange={(e) => { gotoPage(e.target.value - 1) }} /></Pagination.Item> */}
                        <Pagination.Item><div className="d-flex justify-content-center align-items-center" style={{ border: 0, width: "60px" }}><div >{pageIndex + 1}</div></div></Pagination.Item>
                        <Pagination.Item onClick={(e) => { nextPage() }} disabled={!canNextPage}><FontAwesomeIcon fixedWidth icon={faAngleRight} /></Pagination.Item>
                        <Pagination.Item onClick={(e) => { gotoPage(pageCount - 1) }} disabled={!canNextPage}><FontAwesomeIcon fixedWidth icon={faAnglesRight} /></Pagination.Item>
                    </Pagination>
                </div>
            </div>
        </React.Fragment>
    );
};

const SearchTable = (props) => {
    const {
        columns,
        data,
        fetchData,
        exportData,
        count: controlledCount,
    } = props;

    const [localPageSize, setLocalPageSize] = useState(100);
    // const [haveData, setHaveData] = useState();

    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            // Filter: DefaultColumnFilter,
            // disableFilters: true,
            // defaultCanSort: false,
        }),
        []
    );

    var tableInstance = useTable(
        {
            defaultCanSort: false,
            defaultCanFilter: false,
            manualPagination: true,
            manualFilters: true,
            manualSortBy: true,
            columns: columns,
            data: data,
            defaultColumn,
            pageCount: Math.ceil(controlledCount / localPageSize),
        },
        useFilters,
        useSortBy,
        usePagination,
    );

    //console.log("tableInstance", tableInstance);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        setPageSize,
        state: { pageIndex, pageSize, sortBy, filters },
    } = tableInstance;

    useEffect(() => {
        fetchData(pageIndex, pageSize, sortBy, filters);
    }, [fetchData, pageIndex, pageSize, sortBy, filters]);

    useEffect(() => { setPageSize(localPageSize); }, [localPageSize]);

    return (
        <>
            {/* <pre>
                <code>
                    {JSON.stringify(
                        {
                            pageIndex,
                            pageSize,
                            pageCount,
                            canNextPage,
                            canPreviousPage,
                        },
                        null,
                        2
                    )}
                </code>
            </pre> */}

            <TopBottom tableInstance={tableInstance} localPageSize={localPageSize} setLocalPageSize={setLocalPageSize} controlledCount={controlledCount} exportData={exportData} />
            <Table {...getTableProps()} striped hover>
                <thead>
                    {headerGroups.map((headerGroup, headerGroupIndex) => (
                        <React.Fragment key={"headerGroupFragment" + headerGroupIndex.toString()}>
                            <tr  {...headerGroup.getHeaderGroupProps(() => { return { key: { i: headerGroupIndex } } })} className="badge-soft-secondary" >
                                {headerGroup.headers.map((column, columnIndex) => (
                                    // Add the sorting props to control sorting. For this example
                                    // we can add them into the header props
                                    <th {...column.getHeaderProps()}>
                                        {/* <div className="w-100">{column.canFilter ? column.render('Filter', { "width": "100%" }) : <></>}</div> */}
                                        <div className="">{column.canFilter ? column.render('Filter') : <></>}</div>
                                    </th>
                                ))}
                            </tr >
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column, columnIndex) => (
                                    // Add the sorting props to control sorting. For this example
                                    // we can add them into the header props
                                    <th {...column.getHeaderProps()}>
                                        {/* <div className="w-100">{column.canFilter ? column.render('Filter', { "width": "100%" }) : <></>}</div> */}
                                        {/* <div className="">{column.canFilter ? column.render('Filter') : <></>}</div> */}
                                        <div className="pt-2">
                                            <div {...column.getSortByToggleProps()} className="d-flex justify-content-between align-items-center">
                                                {column.render('Header')}
                                                {/* Add a sort direction indicator */}
                                                {
                                                    !column.disableSortBy &&
                                                    <div>
                                                        {column.isSorted
                                                            ? column.isSortedDesc
                                                                ? <FontAwesomeIcon fixedWidth icon={faSortDown} />
                                                                : <FontAwesomeIcon fixedWidth icon={faSortUp} />
                                                            : <FontAwesomeIcon fixedWidth icon={faSort} />}
                                                    </div>
                                                }
                                            </div>
                                            {/* <div onClick={(e) => { toggleHideColumn(column.id); }}>❎</div> */}
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        </React.Fragment>
                    ))}
                </thead>
                {controlledCount > 0 &&
                    <tbody {...getTableBodyProps()}>
                        {rows.map(
                            (row, i) => {
                                prepareRow(row);
                                return (
                                    <tr key={i} {...row.getRowProps()}>
                                        {row.cells.map(cell => {
                                            return (
                                                <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                            )
                                        })}
                                    </tr>
                                )
                            }
                        )}
                    </tbody>
                }
            </Table >
            {controlledCount === 0 && <div className="w-100 p-3 text-center mt-3 mb-3 bg-light">{"No data available in table"}</div>}
            <TopBottom tableInstance={tableInstance} localPageSize={localPageSize} setLocalPageSize={setLocalPageSize} controlledCount={controlledCount} exportData={exportData} />
            {/* <TopBottom></TopBottom> */}

            {/* <pre>
                <code>
                    {JSON.stringify(
                        tableInstance.state,
                        null,
                        2
                    )}
                </code>
            </pre> */}
        </>
    );
};

export default SearchTable;
