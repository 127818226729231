import React from "react";
import AssetsSearch from "../pages/assets/AssetsSearch";
import SrlAssetsSearch from "../srl/pages/SrlAssetsSearch";
import useSrlHelper from "../hooks/useSrlHelper";

const AssetsSearchWrapper = () => {
    const { isSrl } = useSrlHelper();

    return (
        <>
            {
                (isSrl()) ?
                    <>
                        <SrlAssetsSearch></SrlAssetsSearch>
                    </> :
                    <>
                        <AssetsSearch></AssetsSearch>
                    </>
            }
        </>
    );
};

export default AssetsSearchWrapper;
