import React from "react";
import { Button, Form } from "react-bootstrap";
import { X } from "react-feather";

const TextFilter = (props) => {
    const { column: { filterValue, setFilter } } = props;
    return (
        <div className="d-flex">
            <Form.Group className="flex-grow-1">
                <Form.Control
                    type="text"
                    value={filterValue || ''}
                    onChange={e => { setFilter(e.target.value || undefined) }}
                />
            </Form.Group>
            <Button variant="" className="p-0 m-0" onClick={() => { setFilter(""); }}><X /></Button>
        </div>
    );
}

export default TextFilter;
