import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { X } from "react-feather";

const NumericFilter = (props) => {
    const { column: { filterValue, setFilter } } = props;
    const [lessMore, setLessMore] = useState(false);
    const [localValue, setLocalValue] = useState("");

    useEffect(() => {
        setLessMore((filterValue && filterValue.length > 3) ? (filterValue[2] === '>') : false);
        setLocalValue((filterValue && filterValue.length > 3) ? filterValue.substring(3) : "");
    }, [filterValue]);

    useEffect(() => {
        if (!localValue) return;

        var v = localValue;
        if (v.length > 0) {
            setFilter(((!lessMore) ? "±±<" : "±±>") + v);
        }
        else {
            setFilter("");
        }
    }, [localValue, lessMore]);

    return (
        <div className="d-flex justify-content-between align-items-center">
            <span style={{ cursor: "pointer" }} onClick={() => { setLessMore(!lessMore); }} >{!lessMore ? "less than" : "more than"}</span>
            <Form.Group className="flex-grow-1">
                <Form.Control
                    type="number"
                    value={localValue}
                    onChange={(e) => { setLocalValue(e.target.value); }}
                />
            </Form.Group>
            <Button variant="" className="p-0 m-0" onClick={() => { setFilter(""); }}><X /></Button>
        </div>
    );
};

export default NumericFilter;
