import React, { useEffect, useRef, useState } from "react";
import { Badge, Button, Form, Overlay } from "react-bootstrap";
import { Filter, X } from "react-feather";

const SelectionFilter = (props) => {
    const { column } = props;
    // const handleChange = (selectedOption) => {
    //     console.log(`Option selected:`, selectedOption);
    //     column.setFilter(selectedOption.value);
    // };
    const [show, setShow] = useState(false);
    const target = useRef(null);
    const [filterOptions, setFilterOptions] = useState([]);
    const [selectAllChecked, setSelectAllChecked] = useState(false);
    const [selectBlanks, setSelectBlanks] = useState(false);
    const [selectedCount, setSelectedCount] = useState(0);

    // resolve the filterOptions promise if needed and load the data for the selection options
    useEffect(() => {
        var dataOrPromise = column.filterOptions;

        const isPromise = dataOrPromise instanceof Promise;

        if (isPromise) {
            //setLoading(true);
            dataOrPromise
                .then((result) => {
                    setFilterOptions(result);
                    //setLoading(false);
                })
                .catch((err) => {
                    //setError(err);
                    //setLoading(false);
                });
        } else {
            // If it's direct data, set it immediately
            setFilterOptions(dataOrPromise);
        }
    }, [column.filterOptions]);

    useEffect(() => {
        var count = 0;
        var f = filterOptions.reduce(
            (p, v) => {
                if (v["checked"]) {
                    count++;
                    if (p !== null) {
                        return p + "^!^" + v.value
                    } else {
                        return v.value
                    }
                }
                else {
                    // return the filter string as is
                    return p;
                }
            }, null);

        count += selectBlanks ? 1 : 0;

        if (f !== null) {
            column.setFilter("°°>" + f + (selectBlanks ? "^!^°blanks°" : ""));
        }
        else {
            column.setFilter((selectBlanks ? "°°>°blanks°" : ""));
        }
        setSelectedCount(count);
    }, [filterOptions, selectBlanks]);

    const handleSelectAll = (e) => {
        var isChecked = e.target.checked;
        let newFilterOptions = [...filterOptions];
        newFilterOptions.forEach(element => {
            element.checked = isChecked;
        });
        setFilterOptions(newFilterOptions);
        setSelectAllChecked(isChecked);
        setSelectBlanks(isChecked);
    };

    const handleSelectBlanks = (e) => {
        var isChecked = e.target.checked;

        setSelectBlanks(isChecked);
    };

    const handleCheckChange = (e) => {
        let newFilterOptions = [...filterOptions];
        newFilterOptions.forEach(element => {
            if (element.label === e.target.name) {
                element.checked = e.target.checked;
            }
        });
        setFilterOptions(newFilterOptions);
    };

    return (
        <>
            <div className="d-flex flex-nowrap">
                <Button variant="light" ref={target} onClick={() => setShow(!show)}>
                    <Filter size={"1.2em"} className="m-0 p-0 me-1" />
                    <Badge pill bg="dark" text="light">{selectedCount}</Badge>
                </Button>
                <Button variant="" className="p-0 m-0" onClick={() => { handleSelectAll({ target: { checked: false } }) }} ><X /></Button>
            </div>

            <Overlay target={target.current} show={show} placement="bottom-start" rootClose={true} rootCloseEvent={"click"} onHide={() => { setShow(false); }}  >
                {({
                    placement: _placement,
                    arrowProps: _arrowProps,
                    show: _show,
                    popper: _popper,
                    hasDoneInitialMeasure: _hasDoneInitialMeasure,
                    ...props
                }) => (
                    <div {...props}
                        style={{ backgroundColor: "var(--bs-body-bg)", border: "1px solid black", borderRadius: 3, maxHeight: "512px", ...props.style }}
                        className="p-2 overflow-auto">
                        <Form.Check
                            type={"checkbox"}
                            id={"select-all"}
                            label={<><i>Select all</i></>}
                            checked={selectAllChecked}
                            name={"select-all"}
                            onChange={(e) => { handleSelectAll(e) }}
                            style={{ color: "var(--bs-body-color)" }}
                        />
                        {filterOptions && filterOptions.map((type) => (
                            <div key={`default-${type.value}`} className="fg-secondary">
                                <Form.Check
                                    type={"checkbox"}
                                    id={`default-${type.value}`}
                                    label={type.label}
                                    checked={type.checked}
                                    name={type.label}
                                    onChange={(e) => { handleCheckChange(e) }}
                                    style={{ color: "var(--bs-body-color)" }}
                                />
                            </div>
                        ))}
                        <Form.Check
                            type={"checkbox"}
                            id={"(Blanks)"}
                            label={<><i>(Blanks)</i></>}
                            checked={selectBlanks}
                            name={"(Blanks)"}
                            onChange={(e) => { handleSelectBlanks(e) }}
                            style={{ color: "var(--bs-body-color)" }}
                        />
                    </div>
                )}
            </Overlay>
        </>
    );
};

export default SelectionFilter;
